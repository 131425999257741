<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <b-table responsive :items="items" :fields="fields" />
  </div>
</template>

<script>
import axios from '@/components/axios';
import { BTable } from "bootstrap-vue";
import Form from "@/components/form/Form.vue";
const baseApi = process.env.VUE_APP_APIENDPOINT;
export default {
  components: {
    BTable,
    Form,
  },
  props: ["items", "fields", "url"],
  data() {
    return {
      baseApi: process.env.VUE_APP_APIENDPOINT,
      fields: [
        { key: "id", label: "S.R. No" },
        { key: "name", label: "Name" },
        { key: "leaddate", label: "Lead Date" },
        { key: "contactperson", label: "Contact Person" },
        { key: "mobile", label: "Mobile" },
        { key: "email", label: "Email" },
        { key: "address", label: "Address" },
        { key: "area", label: "Area" },
        { key: "city", label: "City" },
        { key: "pincode", label: "Pincode" },
        { key: "state", label: "State" },
        { key: "assignto", label: "Assign to" },
        { key: "leadsource", label: "Lead Source" },
        { key: "subsource", label: "Sub Source" },
      ],
      tableData: [
        {
          id: 40,
          name: "Dickerson",
        },
        // {
        //   id: 21,
        //   name: "Larsen",
        // },
        // {
        //   id: 89,
        //   name: "Geneva",
        // },
      ],
    };
  },
  beforeMount() {
    this.getTableData();
  },
  methods: {
    async getTableData() {
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const requestOptionsUsers = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        url: `${baseApi}/${this.url}`,
      };

      await axios(requestOptionsUsers)
        .then((response) => {
          // response.data.data.data
          //   ? (this.tableData = response.data.data.data)
          this.tableData = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
  },
};
</script>
