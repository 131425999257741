<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <b-card-code>
                <validation-observer ref="simpleRules">
                  <form @submit.prevent="submitForm">
                    <b-row>
                      <b-col md="4">
                        <b-form-group>
                          <label>Project Name</label>
                          <v-select
                            v-model="projectname"
                            label="projectname"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            placeholder="None"
                            :options="projectOptions"
                          />
                        </b-form-group>
                      </b-col>
                      <!-- <b-col md="4">
                        <b-form-group>
                          <label>Plot</label>
                          <v-select
                            v-model="plotno"
                            label="name"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            placeholder="None"
                            :options="plotnoOptions"
                          />
                        </b-form-group>
                      </b-col> -->
                      <b-col md="4">
                        <b-form-group>
                          <label>Plot </label>
                          <v-select
                            v-model="plotno"
                            label="plotno"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            placeholder="None"
                            :options="plotnoOptions"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group>
                          <label>Status</label>
                          <v-select
                            v-model="status"
                            label="name"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            placeholder="None"
                            :options="['Vacant', 'Booked', 'Sold', 'Hold', 'Registered']"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <div class="d-flex justify-content-end">
                      <b-button variant="primary" class="ml-2" @click="searchData">
                        <feather-icon icon="SearchIcon" class="mr-50" />
                        <span class="align-middle">Search</span>
                      </b-button>
                    </div>
                  </form>
                </validation-observer>
              </b-card-code>
              <b-card-code>
                <report-table :data="data" :columns="fields" />
              </b-card-code>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReportTable from "../../../components/ReportTable.vue";
import axios from "@/components/axios";
import Table from "@/components/Table.vue";
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import GoodTableColumnSearch from "../../../components/GoodTableColumnSearch.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Form from "@/components/form/Form.vue";
import moment from "moment";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
} from "bootstrap-vue";
const baseApi = process.env.VUE_APP_APIENDPOINT;
import vSelect from "vue-select";
export default {
  components: {
    ReportTable,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    Form,
    GoodTableColumnSearch,
  },
  data() {
    return {
      result: "",
      data: "",
      projectname: "",
      projectOptions: [],
      plotno: "",
      plotnoOptions: [],
      status: "",
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      fields: [
        {
          field: "project",
          label: "Project",
          // filterOptions: { enabled: true, placeholder: "Search Project" },
        },
        {
          field: "plotno",
          label: "Plot No",
          // filterOptions: { enabled: true, placeholder: "Search Plot No" },
        },
        {
          field: "status",
          label: "Status",
          // filterOptions: { enabled: true, placeholder: "Search Status" },
        },
        {
          field: "carpetarea_sqmt",
          label: "Plot Area(IN SQ. MTR)",
          // filterOptions: {
          //   enabled: true,
          //   placeholder: "Search Plot Area(IN SQ. MTR)",
          // },
        },
        {
          field: "carpetarea_sqyds",
          label: "Plot Area(IN SQ. YARD)",
          // filterOptions: {
          //   enabled: true,
          //   placeholder: "Search Plot Area(IN SQ. YARD)",
          // },
        },
        {
          field: "superarea_sqmt",
          label: "SUPER BUILT UP AREA(IN SQ. MTR)",
          // filterOptions: {
          //   enabled: true,
          //   placeholder: "Search SUPER BUILT UP AREA(IN SQ. MTR)",
          // },
        },
        {
          field: "superarea_sqyds",
          label: "SUPER BUILT UP AREA(IN SQ. YARD)",
          // filterOptions: {
          //   enabled: true,
          //   placeholder: "Search SUPER BUILT UP AREA(IN SQ. YARD)",
          // },
        },
        {
          field: "totalarea_sqmt",
          label: "TOTAL AREA(IN SQ. MTR)",
          // filterOptions: {
          //   enabled: true,
          //   placeholder: "Search TOTAL AREA(IN SQ. MTR)",
          // },
        },
        {
          field: "totalarea_sqyds",
          label: "TOTAL AREA(IN SQ. YARD)",
          // filterOptions: {
          //   enabled: true,
          //   placeholder: "Search TOTAL AREA(IN SQ. YARD)",
          // },
        },
        {
          field: "cornerphase",
          label: "Corner Phase",
          // filterOptions: { enabled: true, placeholder: "Search Corner Phase" },
        },
        {
          field: "gardenphase",
          label: "Garden Phase",
          // filterOptions: { enabled: true, placeholder: "Search Garden Phase" },
        },
        {
          field: "length",
          label: "Length",
          // filterOptions: { enabled: true, placeholder: "Search Length" },
        },
        {
          field: "width",
          label: "Width",
          // filterOptions: { enabled: true, placeholder: "Search Width" },
        },
        {
          field: "rateperyd",
          label: "Rate per Sq. Yard",
          // filterOptions: {
          //   enabled: true,
          //   placeholder: "Search Rate per Sq. Yard",
          // },
        },
        {
          field: "plotamount",
          label: "Total Plot Amount",
          // filterOptions: {
          //   enabled: true,
          //   placeholder: "Search Total Plot Amount",
          // },
        },
        {
          field: "developmentcharge",
          label: "Development Charge (Per Sq. Yard)",
          // filterOptions: {
          //   enabled: true,
          //   placeholder: "Search Development Charge (Per Sq. Yard)",
          // },
        },
        {
          field: "pnorth",
          label: "North",
          // filterOptions: { enabled: true, placeholder: "Search North" },
        },
        {
          field: "psouth",
          label: "South",
          // filterOptions: { enabled: true, placeholder: "Search South" },
        },
        {
          field: "peast",
          label: "East",
          // filterOptions: { enabled: true, placeholder: "Search East" },
        },
        {
          field: "pwest",
          label: "West",
          // filterOptions: { enabled: true, placeholder: "Search West" },
        },
      ],
    };
  },
  mounted() {
    this.getProjectname();
    this.getPlot();
  },
  methods: {
    async searchData() {
      const data = {
        project_id: this.projectname && this.projectname.id,
        // plotno: this.plotno.id,
        status: this.status,
      };
      await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: JSON.stringify(data),
        url: `${this.baseApi}/plotreport`,
      }).then((response) => {
        this.data = response.data.data;
        this.data.map((item) => {
          item.project = item.project && item.project.projectname;
        });
      });
    },
    async getProjectname() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getProject`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.projectOptions = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async getPlot() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/plot`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.plotnoOptions = response.data.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
